import React from "react";
import PropTypes from "prop-types";
import { Message, Container, Table } from "semantic-ui-react";
import RecommendationRow from "./recommendation-row";

const RecommendationTable = ({
  recommendations = [],
  loadingData,
  handleSort,
  sortColumn,
  sortDirection,
  activeFilterPrimary,
  activeFilterSecondary
}) => {
  const haveRecommendations = recommendations.length > 0;
  return (
    <Container className="recommendations-table">
      <Table
        sortable
        unstackable
        celled
        selectable={haveRecommendations}
        size="small"
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={sortColumn === "auditName" ? sortDirection : null}
              onClick={() => {
                handleSort("auditName");
              }}
            >
              Audit & Finding
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={
                sortColumn === "recommendationText" ? sortDirection : null
              }
              onClick={() => {
                handleSort("recommendationText");
              }}
            >
              Recommendation
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === "update" ? sortDirection : null}
              onClick={() => {
                handleSort("update");
              }}
            >
              Last Update
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === "status" ? sortDirection : null}
              onClick={() => {
                handleSort("status");
              }}
            >
              Status
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === "issueDate" ? sortDirection : null}
              onClick={() => {
                handleSort("issueDate");
              }}
            >
              Issue Date
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === "department" ? sortDirection : null}
              onClick={() => {
                handleSort("department");
              }}
            >
              Department
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === "category" ? sortDirection : null}
              onClick={() => {
                handleSort("category");
              }}
            >
              Category
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {recommendations.map(recommendation => (
            <RecommendationRow
              key={recommendation.recommendationId}
              {...{
                recommendation,
                activeFilterPrimary,
                activeFilterSecondary
              }}
            />
          ))}
          {!haveRecommendations && !loadingData && (
            <Table.Row>
              <Table.Cell colSpan={6}>
                <Message info content="No recommendations found!" />
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Container>
  );
};

RecommendationTable.propTypes = {
  recommendations: PropTypes.arrayOf(
    PropTypes.shape({
      recommendationId: PropTypes.string.isRequired
    })
  ).isRequired,
  loadingData: PropTypes.bool.isRequired,
  handleSort: PropTypes.func.isRequired,
  sortColumn: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  activeFilterPrimary: PropTypes.string,
  activeFilterSecondary: PropTypes.string
};

RecommendationTable.defaultProps = {
  activeFilterPrimary: null,
  activeFilterSecondary: null
};

export default RecommendationTable;
